<template>
  <div id="app">
    <vs-button class="mr-2" v-on:click="zoom">zoom in</vs-button>

    <vs-button class="ql-bold" v-on:click="out">zoom out</vs-button>
    <cloud :data="words" :fontSizeMapper="fontSizeMapper" />
  </div>
</template>
 
<script>
import Cloud from "vue-d3-cloud";
import axios from "axios";

export default {
  name: "wordclod",
  watch: {
    $route() {
      this.id = this.$route.params.username;
      this.getData();
    }
  },
  data() {
    return {
      id: this.$route.params.username,
      scroll: 1,
      words: [],
      fontSizeMapper: word => this.setSize(word)
    };
  },
  mounted() {
    this.getData();
  },

  methods: {
    getData() {
      axios
        .get(
          `${process.env.VUE_APP_NOT_SECRET_CODE}/instagram/word_cloud/${this.id}`
        )
        .then(response => {
          this.words = response.data.freqs;
        })
        .catch(function(error) {
          // handle error
          // console.log(error);
        });
    },

    zoom: function() {
      this.scroll += 1;

      let data = this.words;
      this.words = [];
      setTimeout(() => {
        this.words = data;
      }, 0);
    },
    out: function() {
      this.scroll -= 1;
      let data = this.words;
      this.words = [];
      setTimeout(() => {
        this.words = data;
      }, 0);
    },
    setSize(word) {
      return Math.log2(word.value) * (10 + this.scroll);
    }
  },
  components: {
    Cloud
  }
};
</script>

<style >
text {
  cursor: pointer;
}
text:hover {
  font-size: 4rem !important;
  z-index: 99 !important;
  transition: 1s !important;
}
</style>